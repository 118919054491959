import React from 'react';
import Layout from '../components/layout';

export default function Actupage() {
	return (
		<>
			<Layout title="no" description="no" robots={false}>
				<div>
					<h1>Nous n'avons pas cette page</h1>
				</div>
			</Layout>
			<style jsx>{`
				div {
					width: 100%;
					min-height: 100vh;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			`}</style>
		</>
	);
}
